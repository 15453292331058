header {
    /* height: 120vh; vh = viewport height */
    padding-top: 3rem;
    overflow: hidden;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}

header h4 {
    width: 500px;
    margin: 0 auto;
}

/* ======================= CTA ======================= */ /* CTA = call to action */
.cta {
    margin-top: 2.5rem;
    display: flex;      
    gap: 1.2rem;
    justify-content: center;
}   

/* ======================= HEADER SOCIALS ======================= */ 
.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}

.header__socials::after {
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/* ======================= ME ======================= */ 
.me {
    background: linear-gradient(var(--color-primary), 25%, transparent);
    width: 22rem;
    height: 30rem;
    /* position: absolute;
    left: calc(50% - 11rem);    OR left: 50%;     transform: translateX(-50%); */
    align-content: center;


    margin: 4rem auto 0;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 5rem 1.5rem 1.5rem 1.5rem; 
}

/* ======================= SCROLL DOWN ======================= */ 
.scroll__down {
    position: absolute;
    right: -2rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

/* ======================= MEDIA QUERIES (MEDIUM DEVICES) ======================= */
@media screen and (max-width: 1024px) {
    header {
        /* height: 68vh; /* vh = viewport height*/  
    }
}

/* ======================= MEDIA QUERIES (SMALL DEVICES) ======================= */
@media screen and (max-width: 600px) {
    .me {
        width: 100%;
    }

    .header__socials,
    .scroll__down {
        display: none;
    }

    header h4 {
        width: 300px;
    }
}