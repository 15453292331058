.projects__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2.5rem;
}

.projects__item {
    display: flex;
    flex-direction: column;
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
    width: 22rem;
}

.projects__item:hover {
    border-color: var(--color-primary-variant);
    background: transparent;
}

.projects__item-image {
    height: 14rem;
    overflow: hidden;
}

.projects__item-image img {
    border-radius: 1.5rem;
    height: 100%;
}

.projects__item h3 {
    margin: 1.2rem 0 1rem;
}

.projects__item-cta {
    margin-top: auto;
    gap: 1rem;
    margin-bottom: 1rem;
}

.projects_item-body {
    margin-bottom: 1rem;
}


/* ======================= MEDIA QUERIES (MEDIUM DEVICES) ======================= */
@media screen and (max-width: 1024px) {
    .projects__item {
        width: 19rem;
    }
}   

/* ======================= MEDIA QUERIES (SMALL DEVICES) ======================= */
@media screen and (max-width: 600px) {
    .projects__item {
        width: 100%;
    }
}