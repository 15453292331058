.courses__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2.5rem;
}

.courses__item {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
    text-align: center;
    width: 22rem;
}

.courses__item:hover {
    border-color: var(--color-primary-variant);
    background: transparent;
}

.courses__item-image {
    border-radius: 1.5rem;
    overflow: hidden;
}

.courses__item h3 {
    margin-top: 1rem;
}

.courses_item-body {
    padding: 0.5rem;
    color: var(--color-light);
    text-align: left;
}

.courses__item-cta {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 1rem;
}


/* ======================= MEDIA QUERIES (MEDIUM DEVICES) ======================= */
@media screen and (max-width: 1024px) {
    .courses__item {
        width: 19rem;
    }
}

/* ======================= MEDIA QUERIES (SMALL DEVICES) ======================= */
@media screen and (max-width: 600px) {
    .courses__item {
        width: 100%;
    }
}